import React, { Fragment, useState } from "react";
import { navigate } from "gatsby";
import ImageLightbox from "../components/ImageLightbox";
import axios from "axios";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import LandingNav from "containers/Interior/LandingNav";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";


import Footer from "containers/Interior/Footer";
import { ResetCSS } from "common/assets/css/style";
import "common/assets/css/main-page.css";
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from "containers/Interior/interior.style";
import Seo from "components/seo";

import { HairTreatmentsWrapper } from "containers/Interior/hairTreatments.style";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const HairTreatments = () => {

  const allGoogleReviews = [
    {
            title: "Mrinal Mehta",
            image: "/MrinalMehta.png",
            description: "I would highly recommend them. They are doing awesome services and having very caring and helpful staff. They are the best in there work.Thank you team",
            date:"2 months ago"
    },
        {
            title: "Adarsh Shukla",
            image: "/Adarsh Shukla.png",
            description: "Good Experience. Staff were polite and service was done properly. Cost was bit high. I will highly recommend it.",
            date: "a week ago"
        },
        {
            title: "fahad asif",
            image: "/fahad asif.png",
            description: "If  u want to look the same as you were before then Radiance is the only place. You will never be disappointed and the place is cool great staff and they treat u very friendly manner. so please go there if u wanna feel the change and be young again",
            date: "a month ago"
        },
        {
            title: "Praveen",
            image: "/Praveen.png",
            description: "I recently had the pleasure of getting a customized hair wig for women in Delhi, and I couldn't be happier with the experience. The process was smooth, and the final result was nothing short of flawless. The wig matched my natural hair color and texture perfectly, making it virtually indistinguishable from my real hair. It's incredibly comfortable to wear and allows me to experiment with different styles effortlessly. If you're in Delhi and looking for a high-quality customized wig, this is the place to go.",
            date: "2 weeks ago"
        },
        {
            title: "Prem Pandey",
            image: "/Prem Pandey.png",
            description: "Hi, I have started using Hair Patch from Mar 22 from Radiance. The beginning was scary about the patch but I got used to it day by day.The technician were cooperative specially Deepak is patient enough to adjust the hair style which I want and fixed the same.Aatika is The person who explained me the process and explained me how  can maintain it properly for a longer life was really helpful and thanks to herIt is cost effective for the patch except the monthly maintenance cost though",
            date: "2 month ago"
        },
        {
            title: "Anand singh",
            image: "/Anand singh.png",
            description: "If you are looking for hair replacement, then Radiance is the right place. I would highly recommend this procedure. The results are amazing and it looks natural. I am extremely pleased with the results and overall experience. I wish I could add more stars (5 is not enough) to this review. Thank you for giving me a new look.",
            date: "2 month ago"
        },
        {
            title: "Mahesh LIC",
            image: "/Mahesh LIC.png",
            description: "Exceptional Hair Weaving in Delhi I recently experienced hair weaving for men in Delhi, and the results have been exceptional.The process was smooth, and the weave seamlessly blended with my existing hair.It not only added volume but also gave me a natural and fuller look.I feel like a new person, and the confidence boost is remarkable.Delhi's experts in hair weaving truly know their craft.",
            date: "2 weeks ago"
        },
        {
            title: "Shasan Ali",
            image: "/Shasan Ali.png",
            description: "I took a hair patch from this place.. these guys are amazing. I am very happy with there services.",
            date: "2 month ago"
        },
        {
            title: "praveen 2020",
            image: "/praveen 2020.png",
            description: "Delighted with Hair Patch in Delhi Getting a hair patch in Delhi has been one of the best decisions I've made. The process was comfortable, and the results were fantastic. The patch integrates seamlessly with my existing hair, and I can style it just like my own. It's a game- changer, and the compliments keep pouring in.Delhi's experts in hair patches have truly done an outstanding job.",
            date: "a week ago"
        },
        {
            title: "Sneha Mandal",
            image: "/Sneha Mandal.png",
            description: "Friendly and Good behaving Staff. Experienced Hair Stylists. Quality Products Worth for money.Overall Had a Great Experience",
            date: "a month ago"
        },
        {
            title: "Hanshika Marmat",
            image: "/HanshikaMarmat.png",
            description: "Delighted with Hair Patch in Delhi I recently got a hair patch in Delhi, and it's been a game-changer. The process was smooth, and the results are incredible. The patch matches my natural hair perfectly, and I can style it just like my own. It's comfortable to wear, and the confidence it has given me is priceless.Delhi's experts in hair patches have done an outstanding job, and I couldn't be happier.",
            date: "2 weeks ago"
        },
        {
            title: "Aditya Kumar",
            image: "/AdityaKumar.png",
            description: "Hi, I have started using Hair Patch from Mar 22 from Radiance. The beginning was scary about the patch but I got used to it day by day.The technician were cooperative specially Deepak is patient enough to adjust the hair style which I want and fixed the same.Aatika is The person who explained me the process and explained me how  can maintain it properly for a longer life was really helpful and thanks to herIt is cost effective for the patch except the monthly maintenance cost though",
            date: "3 month ago"
        },
        {
            title: "The Kaida",
            image: "/TheKaida.png",
            description: "I Would Highly Recommend Them.They are a very caring and wonderful staff..They are the best...See You Soon Thanks Again..",
            date: "a month ago"
        },
        {
            title: "Shivam Sharma",
            image: "/ShivamSharma.png",
            description: "I am regular a customer to Radiance Hair Studio..... Mr Deepak always guides me the choice my hair style he is very friendly and supportive guy always ensure to meet my requirements",
            date: "2 month ago"
        },
        {
            title: "Aanand Sharma",
            image: "/AanandSharma.png",
            description: "Non-surgical hair replacement for men in Delhi has truly been a game-changer for me. As someone who has been dealing with hair loss for years, I was skeptical at first. However, the results were nothing short of amazing. The procedure was painless, and the new hair seamlessly blended with my existing hair. I feel like I have a full head of hair again, and my confidence has skyrocketed. Delhi's experts in non-surgical hair replacement are doing incredible work, and I can't thank them enough.",
            date: "2 weeks ago"
        },
        {   
            title: "payal dutta",
            image: "/payaldutta.png",
            description: "Exceptional Wig Selection in Delhi I recently explored the world of hair wigs in Delhi, and I'm thoroughly impressed. Delhi offers an outstanding variety of wigs to suit every need and style. Whether you're looking for a natural human hair wig or a synthetic one in a specific color or style, Delhi has it all.The wig sellers here are knowledgeable and helpful, making the shopping experience a breeze",
            date: "a week ago"
        },
     
    ];


  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openLightbox = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage("");
  };

  const initialFormData = {
    name: "",
    code: "",
    mobile: "",
    // Add more form fields here
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData({ ...formData, [name]: newValue });
  };

  const [isValidPhone, setIsValidPhone] = useState(true);

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/; // Matches a 10-digit number
    return phoneRegex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validatePhone(formData.mobile)) {
      // The phone number is valid
      setIsValidPhone(true);

      // Create a new FormData object for the POST request
      const formDataForRequest = new FormData();

      // Append the form fields from the state to the FormData object
      formDataForRequest.append("name", formData.name);
      formDataForRequest.append("code", formData.code);
      formDataForRequest.append("mobile", formData.mobile);

      // console.log("FormData for Request:", formDataForRequest);

      axios
        .post(
          "https://radiance.manageprojects.in/api/api.php",
          formDataForRequest,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          // console.log("Response from API:", response.data.status);
          navigate("/thank-you");
        })
        .catch(function (error) {
          console.log("Error:", error);
        });

      // After successful submission, reset the form to its initial state
      setFormData(initialFormData);
      // Proceed with form submission
    } else {
      // The phone number is not valid
      setIsValidPhone(false);
      // Display an error message or take appropriate action
    }
  };

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo title="Radiance Hair Studio | Hair Wig Solution's" />
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <HairTreatmentsWrapper>
          <InteriorWrapper>
            <LandingNav />
            <ContentWrapper>
              <div className="heroFold" id="bookAppointment">
                <div className="container">
                  <h3 className="hide-desk heroHeading" ><span>Transforming Hair</span><br />Restoring Confidence</h3>
                  {/* <p className="hide-desk heroSubHead" >At 0% interest</p> */}
                  <h1 className="showinmobile">Customize Hair Extensions & Wigs for Men's & Women's at an Unbeatable Price</h1>
                  <div className="contactForm">
                    <div className="formLogo">
                      <a href="/">
                        <img src="/logo.png" alt="Radiance Hair Studios" />
                      </a>
                    </div>
                    
                    <h2 className="formheadingF">Upto <span className="styledf">30% Off</span>: Hair Patch</h2>
                    <form
                      id="enquiryForm"
                      method="post"
                      onSubmit={handleSubmit}
                      noValidate="novalidate"
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 pl-0 pr-2">
                          <input
                            type="number"
                            className="form-control"
                            id="mobile-prefix"
                            name="code"
                            placeholder="+91"
                            value={formData.code}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-8 p-0">
                          <input
                            type="tel"
                            name="mobile"
                            id="mobile"
                            placeholder="Mobile"
                            className="form-control"
                            value={formData.mobile}
                            onChange={handleInputChange}
                          />
                          {isValidPhone ? null : (
                            <div className="error-message">
                              Invalid phone number.
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-action">
                        <button
                          type="submit"
                          id="btnSubmit"
                          className="btn btn-primary"
                        >
                          Book A Free Consultation
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* eslint-disable jsx-a11y/media-has-caption */}
                  <a href="#googleReviews">
                    <img
                      className="googleHeroSecImg"
                      src="/google-reviews.png"
                      alt="google-reviews"
                    />
                  </a>
                  <video autoPlay muted playsInline loop>
                    <source src="/hairvideo7.mp4" type="video/mp4" />
                  </video>
                  
                </div>
              </div>

              {/* Second section  */}

              <section className="ctaWrap" style={{ display: "none" }}>
                <div className="container">
                  <div className="centeredContent">
                    <h3>
                      Lets furnish your dream together at 0% interest | call{" "}
                      <a href="tel:9958585649">+91-99585-85649</a>
                    </h3>
                  </div>
                </div>
              </section>

              <section className="hairConsultation">
                <div className="container">
                  <div className="row justify-content-between">
                    <div
                      className="col-lg-12 col-xl-3 text-center text-xl-left aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-duration="500"
                    >
                      <h2>Our Services Includes:</h2>
                      <div className="hide-desk divider"></div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-2 aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div className="icon-box">
                        <figure>
                          <img
                            className=" ls-is-cached lazyloaded"
                            alt="Hair Wig For Men And Women"
                            width="55"
                            height="55"
                            src="/iconb1.png"
                          />
                        </figure>
                        <div className="consultationText">
                          <h6>Hair Replacement System</h6>
                          <p className="hide-desk">Radiance provides most advance Human Hair Wig System for baldness which gives an insistent result at a very affordable price.</p>
                        </div>
                        
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-2 aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-duration="1500"
                    >
                      <div className="icon-box">
                        <figure>
                          <img
                            className=" ls-is-cached lazyloaded"
                            alt="Up to 8 weeks continuous wear"
                            width="55"
                            height="55"
                            src="/iconb2.png"
                          />
                        </figure>
                        <div className="consultationText">
                        <h6>Hair Patch</h6>
                        <p className="hide-desk" >We only use 100% Human Hair which is processed by a natural method that uses no chemicals or tools, Only Beautiful Hair.</p>
                        </div>
                        
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-2 aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      <div className="icon-box">
                        <figure>
                          <img
                            className=" ls-is-cached lazyloaded"
                            alt="30 Days Money Back Guarantee"
                            width="55"
                            height="55"
                            src="/iconb4.png"
                          />
                        </figure>
                        <div className="consultationText">
                        <h6>Hair Wigs</h6>
                        <p className="hide-desk">At Radiance we make customized hair unit specifically for you which is totally natural & completely undetectable with endless possibilities. Any Length, Volume, or Color which you want.</p>
                        </div>
                        
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-xl-2 aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-duration="2500"
                    >
                      <div className="icon-box">
                        <figure>
                          <img
                            className=" ls-is-cached lazyloaded"
                            alt="Affordable Price"
                            width="55"
                            height="55"
                            src="/w22.png"
                          />
                        </figure>
                        <div className="consultationText">
                        <h6>Hair Bonding</h6>
                        <p className="hide-desk">It's obvious that, sometimes you need a little help from us. Don’t worry… We’re always here for you. Our Customer help desk is there to assist you with any issues.</p>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Third section */}

              <section className="internationalTreatments">
                <div className="container">
                  <div className="row align-items-center">
                    <div
                      className="col col-md-3 aos-init aos-animate"
                      data-aos="fade-right"
                      data-aos-offset="-500"
                      data-aos-duration="1000"
                    >
                      <h2>
                        Results of <br />
                        Our Hair Wig Solutions
                      </h2>
                    </div>
                    <div
                      className="col-md-9 aos-init aos-animate"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <Swiper
                        spaceBetween={15} // Adjust as needed
                        slidesPerView={2} // Display 4 slides per view
                        autoplay={{ delay: 3000 }}
                        navigation
                        breakpoints={{
                          // When viewport width is less than or equal to 767px (typical mobile size)
                          767: {
                            slidesPerView: 4, // Display 2 slides on mobile
                            spaceBetween: 10, // Adjust as needed
                          },
                          // When viewport width is greater than 767px (larger screens)
                          768: {
                            slidesPerView: 4, // Display 1 slide on larger screens
                            spaceBetween: 20, // Adjust as needed
                          },
                        }}
                        // pagination={{ clickable: true }}
                      >
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/1.png"
                              alt="Review 1"
                              onClick={() => openLightbox("/slider/1.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/2.png"
                              alt="Review 2"
                              onClick={() => openLightbox("/slider/2.png")}
                            />
                            <h5>After</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/3.png"
                              alt="Review 3"
                              onClick={() => openLightbox("/slider/3.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/4.png"
                              alt="Review 2"
                              onClick={() => openLightbox("/slider/4.png")}
                            />
                            <h5>After</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/5.png"
                              alt="Review 3"
                              onClick={() => openLightbox("/slider/5.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/6.png"
                              alt="Review 2"
                              onClick={() => openLightbox("/slider/6.png")}
                            />
                            <h5>After</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/7.png"
                              alt="Review 3"
                              onClick={() => openLightbox("/slider/7.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/8.png"
                              alt="Review 2"
                              onClick={() => openLightbox("/slider/8.png")}
                            />
                            <h5>After</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/9.png"
                              alt="Review 3"
                              onClick={() => openLightbox("/slider/9.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/10.png"
                              alt="Review 2"
                              onClick={() => openLightbox("/slider/10.png")}
                            />
                            <h5>After</h5>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="serviceWrap">
                            <img
                              src="/slider/12.png"
                              alt="Review 3"
                              onClick={() => openLightbox("/slider/12.png")}
                            />
                            <h5>Before</h5>
                          </div>
                        </SwiperSlide>
                      </Swiper>

                      {lightboxOpen && (
                        <ImageLightbox
                          imageUrl={selectedImage}
                          onClose={closeLightbox}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {/* Fourth Section  */}
              <div className="dynamicReviews py-6" id="googleReviews2" style={{ display: "none" }}>
                <div className="container">
                  <h2>Google Reviews</h2>
                  <div className="divider"></div>
                  <div
                    class="sk-ww-google-reviews"
                    data-embed-id="207774"
                  ></div>
                  <script
                    src="https://widgets.sociablekit.com/google-reviews/widget.js"
                    async
                    defer
                  ></script>
                  <div className="btnWraps">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                      target="_blank"
                      rel="noreferrer"
                    >
                      All Google Reviews
                    </a>
                  </div>
                </div>
              </div>

              <div className="reviewFold" id="googleReviews" style={{ display: "block" }}>
                <div className="container">
                  <h2 style={{ textAlign: "center" }}>Google Reviews</h2>
                  <div className="divider" style={{margin: '15px auto'}}></div>
                  <Swiper
                    spaceBetween={10} // Adjust as needed
                    slidesPerView={2} // Display 4 slides per view
                    autoplay={{ delay: 3000 }}
                    navigation
                    // pagination={{ clickable: true }}
                    breakpoints={{
                      300: {
                        // width: 768,
                        slidesPerView: 1,
                      },
                      768: {
                        // width: 768,
                        slidesPerView: 4,
                      },
                    }}
                  >

                    {allGoogleReviews.map((review, index) => (
                      <SwiperSlide>
                      <div className="gCard" key={index}>
                        <div className="gHead">
                          <img
                            loading="lazy"
                            width="60"
                            height="60"
                            src={review.image}
                            alt={`Image for ${review.title}`}
                          />
                          <div className="gHeadContent">
                            <h5>{review.title}</h5>
                            <p>{review.date}</p>
                          </div>
                        </div>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <p className="reviewPara" id="scrollable">{review.description}</p>
                        <div className="gFooter">
                          <img
                            width="60"
                            height="60"
                            src="/google.png"
                            alt="Review 1"
                          />
                          <a
                            href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                            target="_blank"
                            rel="noreferrer"
                          >
                            View on Google
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                    ))}
                    

                  </Swiper>
                  <div className="btnWraps">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                      target="_blank"
                      rel="noreferrer"
                    >
                      All Google Reviews
                    </a>
                  </div>
                </div>
              </div>

              {/* Seventh Section */}

              <div className="reviewVideos py-6">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2>Testimonials & Reviews</h2>
                      <div className="divider"></div>
                      <p>
                        Our motive is to free you from the stress of dealing
                        with hair loss and a dedication to giving you back your
                        confidence.
                      </p>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <Swiper
                          spaceBetween={15} // Adjust as needed
                          slidesPerView={2} // Display 4 slides per view
                          autoplay={{ delay: 3000 }}
                          navigation
                          breakpoints={{
                            // When viewport width is less than or equal to 767px (typical mobile size)
                            767: {
                              slidesPerView: 3, // Display 2 slides on mobile
                              spaceBetween: 10, // Adjust as needed
                            },
                            // When viewport width is greater than 767px (larger screens)
                            768: {
                              slidesPerView: 3, // Display 1 slide on larger screens
                              spaceBetween: 20, // Adjust as needed
                            },
                          }}
                          // pagination={{ clickable: true }}
                        >
                          <SwiperSlide>
                            <div className="reviewVideoWrap">
                              <video
                                width="100%"
                                controls
                                playsInline
                                poster="/poster1.jpg"
                              >
                                <source src="/videos/1c.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="reviewVideoWrap">
                              <video
                                width="100%"
                                controls
                                playsInline
                                poster="/poster2.jpg"
                              >
                                <source src="/videos/2c.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="reviewVideoWrap">
                              <video
                                width="100%"
                                controls
                                playsInline
                                poster="/poster3.jpg"
                              >
                                <source src="/videos/3c.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="reviewVideoWrap">
                              <video
                                width="100%"
                                controls
                                playsInline
                                poster="/poster1.jpg"
                              >
                                <source src="/videos/1c.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="reviewVideoWrap">
                              <video
                                width="100%"
                                controls
                                playsInline
                                poster="/poster2.jpg"
                              >
                                <source src="/videos/2c.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fifth Section */}

              <div className="mapSection py-6">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.3546687337284!2d77.12277221455999!3d28.649097290082985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03bd5cf7bcd9%3A0x84348432cd34173!2sRadiance+Hair+Studio!5e0!3m2!1sen!2sin!4v1544348909403"
                        width="100%"
                        height="350px"
                        allowFullScreen=""
                        title="map"
                      ></iframe>
                    </div>
                    <div className="col-md-4">
                      <h3>Rajouri Garden Location</h3>
                      <div className="divider"></div>
                      <ul>
                        <li>
                          <b>Address:</b> A-66, 3rd Floor, Beside Metro Station
                          Gate No 5, Block A, Rajouri Garden, New Delhi, Delhi
                          110027 Visit US
                        </li>
                        <li>
                          <b>Call Us:</b> 099585 85649
                        </li>
                        <li>
                          <b>Write Us:</b> contact@radiancehairstudio.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sixth Section */}

              <div className="whyChooseSection">
                <div className="container">
                  <h2>Why Choose Us?</h2>
                  <div
                    className="divider"
                    style={{ margin: "15px auto" }}
                  ></div>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-md-8">
                      <h3>Radiance Hair Studio</h3>
                      <p>
                        At Radiance Hair Studio, our goal is to make every
                        client look at their very best. Our clients receive
                        high-quality, one-on-one attention. Each client receives
                        a complete consultation before any procedure so he or
                        she can decide the best personal course of action. There
                        are no extra charges if additional treatments are
                        needed.
                      </p>
                      <div className="iconSets">
                        <div className="cardss">
                          <img
                            src="/w1.png"
                            alt="Non-Surgical Hair Replacement"
                          />
                          <h5>Non-Surgical Hair Replacement</h5>
                        </div>
                        <div className="cardss">
                          <img src="/w2.png" alt="Ready to Wear" />
                          <h5>Ready to Wear</h5>
                        </div>
                        <div className="cardss">
                          <img src="/w3.png" alt="Affordable Price" />
                          <h5>Affordable Price</h5>
                        </div>
                        <div className="cardss">
                          <img
                            src="/w4.png"
                            alt="30 Days Money Back Guarantee"
                          />
                          <h5>30 Days Money Back Guarantee</h5>
                        </div>
                        <div className="cardss">
                          <img src="/w5.png" alt="Maintenance" />
                          <h5>Maintenance</h5>
                        </div>
                        <div className="cardss">
                          <img src="/w6.png" alt="Workout Friendly" />
                          <h5>Workout Friendly</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 imgAdjust">
                      <img src="/hair-wigs.png" alt="why choose radiance" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="fixedCtaBtn hide-desk">
                <a className="callBtn" href="tel:09958585649"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg><span>Call us now!</span></a>
                <a className="whatsappBtn" href="https://api.whatsapp.com/send?phone=919958585649&text=Hello%2C%20I%20want%20more%20info%20about%20the%20Hair%20Wigs!"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg><span>Whatsapp Now</span></a>
              </div>
            </ContentWrapper>
            <Footer />
          </InteriorWrapper>
        </HairTreatmentsWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
export default HairTreatments;
